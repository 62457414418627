// 申遗承诺
import DJZT from './sn-table-column-djzt';

const selfData = {
    loading: false,
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [
                {
                    label: "巡查日期",
                    prop: "CJSJ",
                    width: "120"
                },
                {
                    label: "评估",
                    prop: "PG_DESC",
                    width: "80"
                },
                {
                    label: "发生地点（遗产点）",
                    prop: "YCFSWZ",
                    minWidth: ""
                },
                {
                    label: "异常事件",
                    prop: "YCSJ",
                    minWidth: ""
                },
                {
                    label: "巡查员",
                    prop: "XCY",
                    width: "120"
                },
                {
                    label: "异常是否解除",
                    prop: "YCSFJC",
                    width: "120"
                },
                {
                    label: "是否转化预警",
                    prop: "SFZYJ_DESC",
                    width: "120",
                },
                DJZT
            ],
            // 操作列
            columnsBtn: {
                buttonData: []
            }
        },
    },
    snSearch: {
        inputData: [{
            label: '关键字',
            placeholder: "请输入关键字",
            value: 'keyword',
            operateType: 'search',
            isShow: true,
            default: ""
        }],
    },
    snButton: {
        buttonData: [{
            btnType: 'button',
            operateType: 'buttonExport',
            showMethod: 'showExportBtn',
            name: '导出',
            round: true,
            backColor: '#ffcc66',
            color: '#fff'
        }]
    }
};
export default selfData;